import { TRANSACTION_TYPE_CONSTANTS } from './transaction-history/transaction-history.constants';
import { documents as sidDocuments } from '../../../../../store/modules/securitize-id/options';

const disableNewEntityTypes = process.env.VUE_APP_DISABLE_NEW_ENTITY_TYPES === 'true';

const { categories: { documentTypeIndividual } } = sidDocuments;

export const investment = {
  transactionsFields: [
    { key: 'transactionTime', i18nKey: 'investorDetails.investment.label.table.transactionTime' },
    { key: 'tokenName', i18nKey: 'investorDetails.investment.label.table.tokenName' },
    { key: 'roundName', i18nKey: 'investorDetails.investment.label.table.roundName' },
    { key: 'direction', i18nKey: 'investorDetails.investment.label.table.direction' },
    { key: 'amount', i18nKey: 'investorDetails.investment.label.table.amount' },
    { key: 'currencyId', i18nKey: 'investorDetails.investment.label.table.currencyId' },
    { key: 'usdWorth', i18nKey: 'investorDetails.investment.label.table.worth' },
    { key: 'source', i18nKey: 'investorDetails.investment.label.table.source' },
    { key: 'externalTransactionConfirmation', i18nKey: 'investorDetails.investment.label.table.externalTransactionConfirmation' },
    { key: 'actions', label: ' ', tdClass: 'text-nowrap d-inline float-right' },
  ],
  investmentsFields: [
    { key: 'createdAt', i18nKey: 'investorDetails.investmentTableFields.date' },
    { key: 'pledged', i18nKey: 'investorDetails.investmentTableFields.pledge' },
    { key: 'totalFunded', i18nKey: 'investorDetails.investmentTableFields.funded' },
    { key: 'subscriptionAgreementStatus', i18nKey: 'investorDetails.investmentTableFields.subscriptionAgreement' },
    { key: 'subscriptionAgreementSignedAt', i18nKey: 'investorDetails.investmentTableFields.subscriptionAgreementSignedAt' },
    { key: 'status', i18nKey: 'investorDetails.investmentTableFields.investmentStatus' },
  ],
  depositAddressFields: [
    { key: 'tokenName', i18nKey: 'investorDetails.investment.label.table.tokenName' },
    { key: 'type', i18nKey: 'investorDetails.investment.label.table.type' },
    { key: 'address', i18nKey: 'investorDetails.investment.label.table.address' },
    { key: 'investorData', i18nKey: 'investorDetails.investment.label.table.investorData' },
    { key: 'actions', label: ' ', tdClass: 'text-nowrap d-inline float-right' },
  ],
  subscriptionAgreementFields: [
    { value: 'none', i18nKey: 'investorDetails.investment.option.subscriptionAgreement.none' },
    { value: 'requested', i18nKey: 'investorDetails.investment.option.subscriptionAgreement.requested' },
    { value: 'sent', i18nKey: 'investorDetails.investment.option.subscriptionAgreement.sent' },
    { value: 'signed', i18nKey: 'investorDetails.investment.option.subscriptionAgreement.signed' },
    { value: 'confirmed', i18nKey: 'investorDetails.investment.option.subscriptionAgreement.confirmed' },
    { value: 'rejected', i18nKey: 'investorDetails.investment.option.subscriptionAgreement.rejected' },
    { value: 'pre-signed', i18nKey: 'investorDetails.investment.option.subscriptionAgreement.preSigned' },
  ],
  i18nKeys: {
    sources: {
      manual: 'investorDetails.investment.status.source.manual',
      automated: 'investorDetails.investment.status.source.automated',
    },
  },
};

export const general = {
  gender: [
    { value: 'female', i18nKey: 'investorDetails.general.option.gender.female' },
    { value: 'male', i18nKey: 'investorDetails.general.option.gender.male' },
  ],
  types: [
    { value: 'individual', i18nKey: 'common.category.investorType.individual' },
    { value: 'entity', i18nKey: 'common.category.investorType.entity' },
    { value: 'fbo-individual', i18nKey: 'common.category.investorType.fboIndividual' },
    { value: 'fbo-entity', i18nKey: 'common.category.investorType.fboEntity' },
    { value: 'none', i18nKey: 'common.category.investorType.none' },
  ],
  entityType: [
    { value: 'revocable-trust', i18nKey: 'common.category.entityType.revocableTrust' },
    { value: 'irrevocable-trust', i18nKey: 'common.category.entityType.irrevocableTrust' },
    { value: 'limited-general-partnership', i18nKey: 'common.category.entityType.limitedGeneralPartnership' },
    { value: 'llc', i18nKey: 'common.category.entityType.llc' },
    { value: 'corporation', i18nKey: 'common.category.entityType.corporation' },
    { value: 'joint-account', i18nKey: 'common.category.entityType.jointAccount' },
    ...(disableNewEntityTypes ? [] : [
      { value: 'non-profit-foundation', i18nKey: 'common.category.entityType.nonProfitFoundation' },
      { value: 'for-profit-foundation', i18nKey: 'common.category.entityType.forProfitFoundation' },
    ]),
    { value: 'fund', i18nKey: 'common.category.entityType.fund' },
    { value: 'other', i18nKey: 'common.category.entityType.other' },
  ],
};

export const entityTypeMap = {
  'revocable-trust': 'Revocable Trust',
  'irrevocable-trust': 'Irrevocable Trust',
  'limited-general-partnership': 'Limited Partnership/General Partnership',
  llc: 'LLC',
  corporation: 'Corporation',
  'joint-account': 'Joint Account',
  fund: 'Fund',
  'for-profit-foundation': 'For-Profit Foundation',
  'non-profit-foundation': 'Non-Profit Foundation',
  other: 'Other',
};

export const statusStyle = {
  yes: 'outline-success',
  ready: 'outline-success',
  amended: 'outline-danger',
  success: 'outline-success',
  distributed: 'outline-success',
  pending: 'outline-secondary',
  syncing: 'outline-info',
  'syncing-investor-pays': 'outline-info',
  processing: 'outline-info',
  failure: 'outline-danger',
  no: 'outline-danger',
  paid: 'outline-success',
  'not-paid': 'outline-danger',
  rejected: 'outline-danger',
};

export const documents = {
  categories: {
    // server enums
    type: [
      { value: 'passport', i18nKey: 'investorDetails.documents.option.type.passport' },
      { value: 'other', i18nKey: 'investorDetails.documents.option.type.other' },
      { value: 'driver-license', i18nKey: 'investorDetails.documents.option.type.driverLicense' },
      { value: 'national-identity-card', i18nKey: 'investorDetails.documents.option.type.nationalIdentityCard' },
      { value: 'residence-permit', i18nKey: 'investorDetails.documents.option.type.residencePermit' },
      { value: 'visa', i18nKey: 'investorDetails.documents.option.type.visa' },
      { value: 'passport-card', i18nKey: 'investorDetails.documents.option.type.passportCard' },
      { value: 'work-permit', i18nKey: 'investorDetails.documents.option.type.workPermit' },
      { value: 'state-id', i18nKey: 'investorDetails.documents.option.type.stateId' },
      { value: 'uk-biometric-residence-permit', i18nKey: 'investorDetails.documents.option.type.ukBiometricResidencePermit' },
      { value: 'tax-id', i18nKey: 'investorDetails.documents.option.type.taxId' },
      { value: 'voter-id', i18nKey: 'investorDetails.documents.option.type.voterId' },
      { value: 'certificate-of-naturalisation', i18nKey: 'investorDetails.documents.option.type.certificateOfNaturalisation' },
      { value: 'home-office-letter', i18nKey: 'investorDetails.documents.option.type.homeOfficeLetter' },
      { value: 'immigration-status-document', i18nKey: 'investorDetails.documents.option.type.immigrationStatusDocument' },
      { value: 'birth-certificate', i18nKey: 'investorDetails.documents.option.type.birthCertificate' },
      { value: 'selfie', i18nKey: 'investorDetails.documents.option.type.selfie' },
      { value: 'certificate-of-formation', i18nKey: 'investorDetails.documents.option.type.certificateOfFormation' },
      { value: 'articles-of-organization', i18nKey: 'investorDetails.documents.option.type.articlesOfOrganization' },
      { value: 'operating-agreement', i18nKey: 'investorDetails.documents.option.type.operatingAgreement' },
      { value: 'address-proof', i18nKey: 'investorDetails.documents.option.type.addressProof' },
      { value: 'shareholders-list-and-authorised-signers-list', i18nKey: 'investorDetails.documents.option.type.shareholdersListAndAuthorisedSignersList' },
      { value: 'by-laws', i18nKey: 'investorDetails.documents.option.type.byLaws' },
      { value: 'cash-card', i18nKey: 'investorDetails.documents.option.type.cashCard' },
      { value: 'health-insurance-card', i18nKey: 'investorDetails.documents.option.type.healthInsuranceCard' },
      { value: 'my-number-card', i18nKey: 'investorDetails.documents.option.type.myNumberCard' },
      { value: 'driver-history-certificate', i18nKey: 'investorDetails.documents.option.type.driverHistoryCertificate' },
      { value: 'special-permanent-resident-certificate', i18nKey: 'investorDetails.documents.option.type.specialPermanentResidentCertificate' },
      { value: 'resident-record-card', i18nKey: 'investorDetails.documents.option.type.residentRecordCard' },
    ],
    face: [
      { value: 'front', i18nKey: 'investorDetails.documents.option.face.front' },
      { value: 'back', i18nKey: 'investorDetails.documents.option.face.back' },
    ],
  },
  fields: [
    { key: 'image', i18nKey: 'investorDetails.documents.label.table.image' },
    { key: 'documentTitle', i18nKey: 'investorDetails.documents.label.table.documentTitle', tdClass: 'align-middle' },
    { key: 'type', i18nKey: 'investorDetails.documents.label.table.type', tdClass: 'align-middle' },
    { key: 'documentFace', i18nKey: 'investorDetails.documents.label.table.documentFace', tdClass: 'align-middle' },
    { key: 'createdAt', i18nKey: 'investorDetails.documents.label.table.createdAt', tdClass: 'align-middle' },
    { key: 'origin', i18nKey: 'investorDetails.documents.label.table.origin', tdClass: 'align-middle' },
    { key: 'token', i18nKey: 'investorDetails.documents.label.table.token', tdClass: 'align-middle' },
    { key: 'actions', label: ' ', tdClass: 'text-nowrap d-inline float-right' },
  ],
  i18nKeys: {
    types: {
      passport: 'investorDetails.documents.status.type.passport',
      other: 'investorDetails.documents.status.type.other',
      'driver-license': 'investorDetails.documents.status.type.driverLicense',
      'national-identity-card': 'investorDetails.documents.status.type.nationalIdentityCard',
      'residence-permit': 'investorDetails.documents.status.type.residencePermit',
      visa: 'investorDetails.documents.status.type.visa',
      'passport-card': 'investorDetails.documents.status.type.passportCard',
      'work-permit': 'investorDetails.documents.status.type.workPermit',
      'state-id': 'investorDetails.documents.status.type.stateId',
      'uk-biometric-residence-permit': 'investorDetails.documents.status.type.ukBiometricResidencePermit',
      'tax-id': 'investorDetails.documents.status.type.taxId',
      'voter-id': 'investorDetails.documents.status.type.voterId',
      'certificate-of-naturalisation': 'investorDetails.documents.status.type.certificateOfNaturalisation',
      'home-office-letter': 'investorDetails.documents.status.type.homeOfficeLetter',
      'immigration-status-document': 'investorDetails.documents.status.type.immigrationStatusDocument',
      'birth-certificate': 'investorDetails.documents.status.type.birthCertificate',
      selfie: 'investorDetails.documents.option.type.selfie',
      'certificate-of-formation': 'investorDetails.documents.option.type.certificateOfFormation',
      'articles-of-organization': 'investorDetails.documents.option.type.articlesOfOrganization',
      'operating-agreement': 'investorDetails.documents.option.type.operatingAgreement',
      'address-proof': 'investorDetails.documents.option.type.addressProof',
      'shareholders-list-and-authorised-signers-list': 'investorDetails.documents.option.type.shareholdersListAndAuthorisedSignersList',
      'by-laws': 'investorDetails.documents.option.type.byLaws',
      'cash-card': 'investorDetails.documents.option.type.cashCard',
      'health-insurance-card': 'investorDetails.documents.option.type.healthInsuranceCard',
      'my-number-card': 'investorDetails.documents.option.type.myNumberCard',
      'driver-history-certificate': 'investorDetails.documents.option.type.driverHistoryCertificate',
      'special-permanent-resident-certificate': 'investorDetails.documents.option.type.specialPermanentResidentCertificate',
      'resident-record-card': 'investorDetails.documents.option.type.residentRecordCard',
    },
    faces: {
      front: 'investorDetails.documents.status.face.front',
      back: 'investorDetails.documents.status.face.back',
    },
    documentOrigin: {
      internal: 'investorDetails.documents.status.origin.internal',
      'securitize-id': 'investorDetails.documents.status.origin.securitizeId',
    },
  },
};

const creatorMockGetters = entities => (value) => {
  const entity = entities.find(el => el.value === value);
  return (entity && entity.i18nKey) || 'undefined';
};
export const getGenderByValue = creatorMockGetters(general.gender);
export const getTypeByValue = creatorMockGetters(general.types);

export const tokensWallets = {
  wallets: [
    { key: 'name', i18nKey: 'investorDetails.tokensWallets.label.table.wallets.name' },
    { key: 'address', i18nKey: 'investorDetails.tokensWallets.label.table.wallets.address' },
    { key: 'walletType', i18nKey: 'investorDetails.tokensWallets.label.table.wallets.walletType' },
    { key: 'owner', i18nKey: 'investorDetails.tokensWallets.label.table.wallets.owner' },
    { key: 'status', i18nKey: 'investorDetails.tokensWallets.label.table.wallets.status' },
    { key: 'tokensPending', i18nKey: 'investorDetails.tokensWallets.label.table.wallets.tokensPending' },
    { key: 'tokensHeld', i18nKey: 'investorDetails.tokensWallets.label.table.wallets.tokensHeld' },
    { key: 'creationDate', i18nKey: 'investorDetails.tokensWallets.label.table.wallets.creationDate' },
    { key: 'tokenName', i18nKey: 'investorDetails.tokensWallets.label.table.wallets.tokenName' },
    { key: 'actions', label: ' ', tdClass: 'text-nowrap d-inline float-right' },
  ],
  issuances: [
    { key: 'id', i18nKey: 'investorDetails.tokensWallets.label.table.issuances.id' },
    { key: 'creationDate', i18nKey: 'investorDetails.tokensWallets.label.table.issuances.creationDate' },
    { key: 'executionDate', i18nKey: 'investorDetails.tokensWallets.label.table.issuances.executionDate' },
    { key: 'issueAmount', i18nKey: 'investorDetails.tokensWallets.label.table.issuances.issueAmount' },
    { key: 'source', i18nKey: 'investorDetails.tokensWallets.label.table.issuances.source' },
    { key: 'target', i18nKey: 'investorDetails.tokensWallets.label.table.issuances.target' },
    { key: 'status', i18nKey: 'investorDetails.tokensWallets.label.table.issuances.status' },
    { key: 'issuanceTime', i18nKey: 'investorDetails.tokensWallets.label.table.issuances.issuanceTime' },
    { key: 'description', i18nKey: 'investorDetails.tokensWallets.label.table.issuances.description' },
    { key: 'tokenName', i18nKey: 'investorDetails.tokensWallets.label.table.issuances.tokenName' },
    { key: 'actions', label: ' ', tdClass: 'text-nowrap d-inline float-right' },
  ],
  walletStatuses: [
    { value: 'syncing', i18nKey: 'investorDetails.tokensWallets.option.walletStatuses.syncing' },
    { value: 'syncing-investor-pays', i18nKey: 'investorDetails.tokensWallets.option.walletStatuses.syncingInvestorPays' },
    { value: 'pending', i18nKey: 'investorDetails.tokensWallets.option.walletStatuses.pending' },
    { value: 'ready', i18nKey: 'investorDetails.tokensWallets.option.walletStatuses.ready' },
    { value: 'rejected', i18nKey: 'investorDetails.tokensWallets.option.walletStatuses.rejected' },
    { value: 'cancelled', i18nKey: 'investorDetails.tokensWallets.option.walletStatuses.cancelled' },
  ],
  editableWalletStatuses: [
    { value: 'syncing', i18nKey: 'investorDetails.tokensWallets.option.walletStatuses.syncing' },
    { value: 'pending', i18nKey: 'investorDetails.tokensWallets.option.walletStatuses.pending' },
    { value: 'ready', i18nKey: 'investorDetails.tokensWallets.option.walletStatuses.ready' },
  ],
  issuanceStatuses: [
    { value: 'pending', i18nKey: 'investorDetails.tokensWallets.option.issuanceStatuses.pending' },
    { value: 'processing', i18nKey: 'investorDetails.tokensWallets.option.issuanceStatuses.processing' },
    { value: 'success', i18nKey: 'investorDetails.tokensWallets.option.issuanceStatuses.success' },
    { value: 'failure', i18nKey: 'investorDetails.tokensWallets.option.issuanceStatuses.failure' },
    { value: 'amended', i18nKey: 'investorDetails.tokensWallets.option.issuanceStatuses.amended' },
  ],
  transactionHistoryTypes: [
    { value: TRANSACTION_TYPE_CONSTANTS.ISSUANCE, i18nKey: 'investorDetails.tokensWallets.transactionHistory.options.transactionHistoryTypes.issuance' },
    { value: TRANSACTION_TYPE_CONSTANTS.TRANSFER_OK, i18nKey: 'investorDetails.tokensWallets.transactionHistory.options.transactionHistoryTypes.transferOk' },
    { value: TRANSACTION_TYPE_CONSTANTS.DESTROY, i18nKey: 'investorDetails.tokensWallets.transactionHistory.options.transactionHistoryTypes.destroy' },
    { value: TRANSACTION_TYPE_CONSTANTS.REDEMPTION, i18nKey: 'investorDetails.tokensWallets.transactionHistory.options.transactionHistoryTypes.redemption' },
  ],
  i18nKeys: {
    walletTypes: {
      normal: 'investorDetails.tokensWallets.status.walletType.normal',
      omnibus: 'investorDetails.tokensWallets.status.walletType.omnibus',
    },
    sources: {
      manual: 'investorDetails.tokensWallets.status.source.manual',
      automated: 'investorDetails.tokensWallets.status.source.automated',
    },
    walletsList: {
      treasury: 'investorDetails.tokensWallets.status.walletsList.treasury',
      wallet: 'investorDetails.tokensWallets.status.walletsList.wallet',
    },
  },
};
export const payouts = {
  fields: [
    { key: 'distributionType', i18nKey: 'investorDetails.payouts.label.table.distributionType', tdClass: 'align-middle' },
    { key: 'confirmationDate', i18nKey: 'investorDetails.payouts.label.table.confirmationDate', tdClass: 'align-middle' },
    { key: 'payoutMethod', i18nKey: 'investorDetails.payouts.label.table.payoutMethod', tdClass: 'align-middle' },
    { key: 'units', i18nKey: 'investorDetails.payouts.label.table.units', tdClass: 'align-middle' },
    { key: 'withholding', i18nKey: 'investorDetails.payouts.label.table.withholding', tdClass: 'align-middle' },
    { key: 'netAmount', i18nKey: 'investorDetails.payouts.label.table.netAmount', tdClass: 'align-middle' },
  ],
  statusesList: [
    { value: 'paid', i18nKey: 'investorDetails.payouts.option.statusesList.paid' },
    { value: 'not-paid', i18nKey: 'investorDetails.payouts.option.statusesList.notPaid' },
    { value: 'rejected', i18nKey: 'investorDetails.payouts.option.statusesList.rejected' },
  ],
  payoutTypeOptions: [
    { value: 'dividend', i18nKey: 'investorDetails.payouts.option.payoutTypeOptions.dividend' },
    { value: 'buyback', i18nKey: 'investorDetails.payouts.option.payoutTypeOptions.buyback' },
    { value: 'profit-sharing', i18nKey: 'investorDetails.payouts.option.payoutTypeOptions.profitSharing' },
    { value: 'other', i18nKey: 'investorDetails.payouts.option.payoutTypeOptions.other' },
  ],
};

export const affiliateFields = [
  { key: 'status', i18nKey: 'investorDetails.affiliate.label.table.status', tdClass: 'align-middle' },
  { key: 'createdAt', i18nKey: 'investorDetails.affiliate.label.table.createdAt', tdClass: 'align-middle' },
  { key: 'comment', i18nKey: 'investorDetails.affiliate.label.table.comment', tdClass: 'align-middle' },
];

export const kyc = {
  statuses: [
    { value: 'none', i18nKey: 'investorDetails.kyc.option.status.none' },
    { value: 'processing', i18nKey: 'investorDetails.kyc.option.status.processing' },
    { value: 'updates-required', i18nKey: 'investorDetails.kyc.option.status.updatesRequired' },
    { value: 'manual-review', i18nKey: 'investorDetails.kyc.option.status.manualReview' },
    { value: 'verified', i18nKey: 'investorDetails.kyc.option.status.verified' },
    { value: 'rejected', i18nKey: 'investorDetails.kyc.option.status.rejected' },
    { value: 'expired', i18nKey: 'investorDetails.kyc.option.status.expired' },
  ],
  accreditation: [
    { value: 'none', i18nKey: 'investorDetails.kyc.option.accreditation.none' },
    { value: 'pending', i18nKey: 'investorDetails.kyc.option.accreditation.pending' },
    { value: 'verified', i18nKey: 'investorDetails.kyc.option.accreditation.verified' },
    { value: 'rejected', i18nKey: 'investorDetails.kyc.option.accreditation.rejected' },
    { value: 'no', i18nKey: 'investorDetails.kyc.option.accreditation.no' },
    { value: 'expired', i18nKey: 'investorDetails.kyc.option.accreditation.expired' },
    { value: 'updates-required', i18nKey: 'investorDetails.kyc.option.accreditation.updatesRequired' },
    { value: 'not-accredited', i18nKey: 'investorDetails.kyc.option.accreditation.notAccredited' },
    { value: 'processing', i18nKey: 'investorDetails.kyc.option.accreditation.processing' },
  ],
  tokenQualification: [
    { value: 'none', i18nKey: 'investorDetails.kyc.option.tokenQualification.none' },
    { value: 'pending', i18nKey: 'investorDetails.kyc.option.tokenQualification.pending' },
    { value: 'verified', i18nKey: 'investorDetails.kyc.option.tokenQualification.verified' },
    { value: 'rejected', i18nKey: 'investorDetails.kyc.option.tokenQualification.rejected' },
    { value: 'no', i18nKey: 'investorDetails.kyc.option.tokenQualification.no' },
    { value: 'expired', i18nKey: 'investorDetails.kyc.option.tokenQualification.expired' },
    { value: 'not-accredited', i18nKey: 'investorDetails.kyc.option.accreditation.notAccredited' },
    { value: 'updates-required', i18nKey: 'investorDetails.kyc.option.tokenQualification.updatesRequired' },
  ],
};

export const syncSecIdActionEnum = {
  SYNC: 'sync',
  FORCE_SYNC: 'forceSync',
};

export const legalSigners = {
  legalSignerFields: [
    {
      key: 'signerType',
      label: 'Legal Signers Type',
      tdClass: 'align-middle',
      thClass: 'align-middle',
    },
    {
      key: 'name',
      label: 'Name',
      tdClass: 'align-middle',
      thClass: 'align-middle',
    },
    {
      key: 'taxId',
      label: 'ID',
      tdClass: 'align-middle',
      thClass: 'align-middle',
    },
    {
      key: 'actions',
      label: '',
      tdClass: 'text-nowrap d-inline',
    },
  ],
  entityTypeList: [
    { value: 'revocable-trust', i18nKey: 'common.category.entityType.revocableTrust' },
    { value: 'irrevocable-trust', i18nKey: 'common.category.entityType.irrevocableTrust' },
    { value: 'limited-general-partnership', i18nKey: 'common.category.entityType.limitedGeneralPartnership' },
    { value: 'llc', i18nKey: 'common.category.entityType.llc' },
    { value: 'corporation', i18nKey: 'common.category.entityType.corporation' },
    { value: 'joint-account', i18nKey: 'common.category.entityType.jointAccount' },
    { value: 'other', i18nKey: 'common.category.entityType.other' },
  ],
  signerTypeList: [
    {
      value: 'individual',
      i18nKey: 'common.category.investorType.individual',
    },
    {
      value: 'entity',
      i18nKey: 'common.category.investorType.entity',
    },
  ],
  documentTypeIndividual,
  documentTypeEntity: {
    'revocable-trust': [
      { value: { docCategory: 'signer-entity', docType: 'document-or-certificate-of-trust' }, text: 'Certificate of Trust' },
      { value: { docCategory: 'signer-entity', docType: 'list-of-trustees' }, text: 'List of Trustees' },
    ],
    'irrevocable-trust': [
      { value: { docCategory: 'signer-entity', docType: 'document-or-certificate-of-trust' }, text: 'Certificate of Trust' },
      { value: { docCategory: 'signer-entity', docType: 'list-of-trustees' }, text: 'List of Trustees' },
    ],
    'limited-general-partnership': [
      { value: { docCategory: 'signer-entity', docType: 'certificate-of-formation' }, text: 'Certificate of Formation' },
      { value: { docCategory: 'signer-entity', docType: 'partnership-agreement' }, text: 'Partnership Agreement' },
      { value: { docCategory: 'signer-entity', docType: 'lp-list-and-authorised-signers-list' }, text: 'LP list and authorized signers list' },
    ],
    llc: [
      { value: { docCategory: 'signer-entity', docType: 'certificate-of-formation' }, text: 'Certificate of Formation' },
      { value: { docCategory: 'signer-entity', docType: 'articles-of-organization' }, text: 'Articles of Organization' },
      { value: { docCategory: 'signer-entity', docType: 'operating-agreement' }, text: 'Operating Agreement' },
      { value: { docCategory: 'signer-entity', docType: 'members-and-authorised-signers-list' }, text: 'Members\'s list and authorized signers list' },
    ],
    corporation: [
      { value: { docCategory: 'signer-entity', docType: 'by-laws' }, text: 'By-laws' },
      { value: { docCategory: 'signer-entity', docType: 'certificate-of-formation' }, text: 'Certificate of Formation' },
      { value: { docCategory: 'signer-entity', docType: 'articles-of-corporation' }, text: 'Articles of Corporation' },
      { value: { docCategory: 'signer-entity', docType: 'shareholders-list-and-authorised-signers-list' }, text: 'Shareholder\'s list and authorized signers list' },
    ],
    other: [{ value: { docCategory: 'signer-entity', docType: 'other' }, text: 'Other' }],
  },
};
