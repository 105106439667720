<template>
  <cp-general-modal
    ref="addWallet"
    :title="$t('investorDetails.addInvestorWalletModal.title')"
    :ok-disabled="disabled"
    @onOk="addWallet"
  >
    <cp-input
      name="name"
      :label="$t('investorDetails.addInvestorWalletModal.input.name')"
      validate="required"
    />
    <cp-input
      name="address"
      :label="$t('investorDetails.addInvestorWalletModal.input.address')"
      validate="required"
    />
  </cp-general-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import CpInput from '~/components/common/standalone-components/inputs/cp-input';
import CpGeneralModal from '~/components/common/modals-components/general-modal';

export default {
  name: 'AddInvestorWallet',
  components: {
    CpInput,
    CpGeneralModal,
  },
  props: {
    tokenId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      disabled: false,
      currentTokenId: this.tokenId,
    };
  },
  computed: {
    ...mapGetters('issuersInfo', ['issuerInfo']),
    blockchainType() {
      return this.issuerInfo.blockchain;
    },
  },
  watch: {
    tokenId(currentTokenId) {
      this.currentTokenId = currentTokenId;
    },
  },
  methods: {
    ...mapActions('investors', ['addInvestorDetailTokenWallet']),
    show() {
      this.$refs.addWallet.show();
    },
    addWallet(body) {
      this.disabled = true;
      const { idIssuer: issuerId, detailsId: userId } = this.$route.params;
      this.addInvestorDetailTokenWallet({
        issuerId,
        userId,
        body: { ...body, tokenId: this.currentTokenId, actionMadeBy: 'operator', ignoreInvestorPays: true },
      }).then(() => {
        this.$emit('walletIsUpdated');
        this.$refs.addWallet.hide();
      }).finally(() => {
        this.disabled = false;
      });
    },
  },
};
</script>
