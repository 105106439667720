<template>
  <cp-general-modal
    ref="editWallet"
    :title="$t('investorDetails.editInvestorWalletModal.title')"
    :ok-disabled="disabled"
    :default-value="item"
    @shown="setCurrentWalletStatus"
    @onOk="updateWallet"
  >
    <cp-input
      name="name"
      :label="$t('investorDetails.editInvestorWalletModal.input.name')"
      validate="required"
    />
    <cp-input
      name="address"
      :label="$t('investorDetails.editInvestorWalletModal.input.address')"
      :validate="validation"
      :disabled="disableByConditions"
    />
    <cp-select
      v-model="status"
      name="status"
      :label="$t('investorDetails.editInvestorWalletModal.input.status')"
      validate="required"
      :options="walletStatuses"
    />
  </cp-general-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { tokensWallets } from '~/pages/_idIssuer/investors/details/components/options';

import CpInput from '~/components/common/standalone-components/inputs/cp-input';
import CpSelect from '~/components/common/standalone-components/inputs/cp-select';
import CpGeneralModal from '~/components/common/modals-components/general-modal';

export default {
  name: 'EditInvestorWalletModal',
  components: {
    CpInput,
    CpSelect,
    CpGeneralModal,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      status: null,
      disabled: false,
      walletStatuses: tokensWallets.editableWalletStatuses,
      updateWalletProcess: false,
      disabledEditableConditions: {
        status: ['syncing', 'ready', 'rejected', 'cancelled'],
      },
    };
  },
  computed: {
    ...mapGetters('issuersInfo', ['issuerInfo']),
    disableByConditions() {
      return Object.keys(this.disabledEditableConditions)
        .some((conditionIndex) => {
          if (this.disabledEditableConditions[conditionIndex] && Array.isArray(this.disabledEditableConditions[conditionIndex])) {
            return this.disabledEditableConditions[conditionIndex].includes(this.item[conditionIndex]);
          }
          return this.item[conditionIndex] === this.disabledEditableConditions[conditionIndex];
        });
    },
    blockchainType() {
      return this.issuerInfo.blockchain;
    },
    validation() {
      return `required|${this.status !== 'pending' ? `is:${this.item.address}` : ''}`;
    },
  },
  methods: {
    ...mapActions('investors', ['editInvestorDetailTokenWallet']),
    show() {
      this.$refs.editWallet.show();
    },
    setCurrentWalletStatus() {
      this.status = this.item.status;
    },
    updateWallet(body) {
      this.disabled = true;
      this.editInvestorDetailTokenWallet({
        issuerId: this.$route.params.idIssuer,
        userId: this.$route.params.detailsId,
        tokenWalletId: body.id,
        body,
      }).then(() => {
        this.$emit('walletIsUpdated');
        this.$refs.editWallet.hide();
      }).finally(() => {
        this.disabled = false;
      });
    },
  },
};
</script>
